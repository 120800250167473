@import url("./assets/fonts/OpenSans/stylesheet.css");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Open Sans";
}

html,
body {
  max-width: 100vw;
  min-width: 360px;
  overflow-x: hidden;
  height: 100%;
}

body > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

.input-range__label{
  display: none;
}

.input-range__slider{
  background: #fff;
  border: 1px solid #979797;
}

.input-range__track--active{
  background: #358ed3;
}
